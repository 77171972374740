<template>
  <div class="relative overflow-hidden pb-10 lg:pb-0 pt-18">
    <main>
      <!-- 1ª Seção -->
      <div class="pt-10 sm:pt-16 lg:pt-8 pb-14 lg:overflow-hidden">
        <div class="mx-auto max-w-7xl lg:px-8">
          <div class="lg:grid lg:grid-cols-2 lg:gap-8">
            <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
              <div class="lg:py-24">
                <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                  <span class="text-primary">A </span>
                  <span class="text-gray-700">solução </span>
                  <span class="text-primary">que sua empresa precisa.</span>
                </h1>
                <p class="mt-3 text-base text-justify text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  Com mais de 10 anos de experiência no mercado, atuamos em todos os setores da automação industrial, desenvolvendo produtos e soluções que agregam valor para nossos clientes.
                </p>
                <div class="mt-10 sm:mt-12">
                  <form class="sm:max-w-xl sm:mx-auto lg:mx-0">
                    <div class="sm:flex">
                      <div class="mt-3 sm:mt-0">
                        <button @click="$router.push('/produtos')" type="button" class="block w-full py-3 px-4 rounded-md shadow bg-primary text-white font-medium hover:bg-primarylight focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primarydark focus:ring-offset-primarydark">Ver Mais</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative lg:contents hidden">
              <img style="animation-name: animation; animation-duration: 2s; animation-delay: 0s; animation-iteration-count: infinite; animation-direction: alternate; animation-fill-mode: none; animation-play-state: running;" class="w-full flex justify-center ml-20 lg:h-full lg:w-auto" src="@/assets/section.svg" alt="Section">
            </div>
          </div>
        </div>
      </div>
      <!-- Soluções  -->
      <div class="relative bg-primary text-gray-100 pt-16 sm:pt-24">
        <div class="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <div>
            <h2 class="text-base font-semibold tracking-wider text-secundary uppercase">Soluções</h2>
            <p class="mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl hover:pulse">
              Nossos Produtos e Serviços
            </p>
            <p class="mt-5 max-w-prose mx-auto text-xl ">
              Para melhor atender as necessidades de projeto de nossos clientes, trabalhamos em ambas as vertentes de trabalho.
            </p>
          </div>
          <div class="py-24 text-gray-100 grid grid-cols-1 gap-8 lg:grid-cols-3">
            <!-- 1º Card -->
            <div class="pt-1 lg:pt-6">
              <div class="flow-root bg-primarydark rounded-lg px-6 pb-8 hover:shadow-lg anPulse h-full">
                <div class="-mt-6">
                  <div>
                    <span class="inline-flex p-3 bg-secundary rounded-md shadow-lg">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
                      </svg>
                    </span>
                  </div>
                  <h3 class="mt-8 text-lg font-medium tracking-tight">Projetos Eletrônicos</h3>
                  <p class="mt-5 text-base">
                    Desenvolvimento desde o protótipo até o produto final, em placas de circuito impresso.
                  </p>
                </div>
              </div>
            </div>
            <!-- 2º Card -->
            <div class="pt-12 lg:pt-6">
              <div class="flow-root bg-primarydark rounded-lg px-6 pb-8 hover:shadow-lg anPulse h-full">
                <div class="-mt-6">
                  <div>
                    <span class="inline-flex p-3 bg-secundary rounded-md shadow-lg">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                      </svg>
                    </span>
                  </div>
                  <h3 class="mt-8 text-lg font-medium tracking-tight">Programação</h3>
                  <p class="mt-5 text-base">
                    Elaboração de software específico de acordo com a necessidade do projeto e do cliente.
                  </p>
                </div>
              </div>
            </div>
            <!-- 3º Card -->
            <div class="pt-12 lg:pt-6">
              <div class="flow-root bg-primarydark rounded-lg px-6 pb-8 hover:shadow-lg anPulse h-full">
                <div class="-mt-6">
                  <div>
                    <span class="inline-flex p-3 bg-secundary rounded-md shadow-lg">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                      </svg>
                    </span>
                  </div>
                  <h3 class="mt-8 text-lg font-medium tracking-tight">Painéis Elétricos</h3>
                  <p class="mt-5 text-base">
                    Análise e preparo de desenho elétrico e montagem de paineis sob demanda.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Sobre -->
      <div id="contato" class="max-w-7xl mx-auto pt-20 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h2 class="text-sm font-semibold text-secundary tracking-wide uppercase">Outras Informações</h2>
        <p class="mt-3 text-3xl font-extrabold text-primary">Verifique nossa seção de <span @click="$router.push('/suporte')" class="text-gray-700 cursor-pointer">Suporte</span> e <span @click="$router.push('/aprenda')" class="text-gray-700 cursor-pointer">Aprenda</span>.</p>
        <p class="mt-5 text-lg text-gray-700">Valorizamos o suporte ao cliente, e é por isso que preparamos uma seção exclusiva com as perguntas mais frequentes sobre nossos produtos, e além disso, sinta-se confortável em nos contactar pelos meios abaixo.</p>
        <div class="mt-10 grid grid-cols-1 gap-16 sm:grid-cols-2 lg:grid-cols-6">
          <div class="col-span-2 lg:col-span-1 text-center">
            <div>
              <span class="inline-flex items-center justify-center p-3 bg-primary text-white rounded-md shadow-lg">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
              </svg>
              </span>
            </div>
            <h3 class="mt-2 text-lg font-medium text-gray-900 tracking-tight">Endereço</h3>
            <p class="mt-2 text-base text-gray-500">
              <span class="block">Rua Santana Gomes,</span>
              <span class="block">151 - Bonfim</span>
              <span class="block">Campinas - SP</span>
            </p>
          </div>
          <div class="col-span-2">
            <button @click="email()" @mouseenter="hoverEmail = true" @mouseleave="hoverEmail = false" type="button" class="anPulse inline-flex w-full items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primarydark">
              <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              {{ hoverEmail ? 'vwsolucoes@gmail.com' : 'Email' }}
              <span @click.stop="copy('vwsolucoes@gmail.com')" v-if="hoverEmail" class="ml-auto rounded-full bg-white text-primary text-sm px-2">Copiar</span>
            </button>
            <button @click="ligar()" @mouseenter="hoverLigar = true" @mouseleave="hoverLigar = false" type="button" class="anPulse mt-2 inline-flex w-full items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primarydark">
              <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
              </svg>
              {{ hoverLigar ? '(19) 3229-4700' : 'Ligar' }}
              <span @click.stop="copy('(19) 3229-4700')" v-if="hoverLigar" class="ml-auto rounded-full bg-white text-primary text-sm px-2">Copiar</span>
            </button>
            <button @click="whatsapp()" @mouseenter="hoverWhatsapp = true" @mouseleave="hoverWhatsapp = false" type="button" class="anPulse mt-2 inline-flex w-full items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primarydark">
              <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-3 h-5 w-5" width="24" height="24" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" fill="#ffff"/></svg>
              {{ hoverWhatsapp ? '(19) 9 9998-7126' : 'Whatsapp' }}
              <span @click.stop="copy('(19) 9 9998-7126')" v-if="hoverWhatsapp" class="ml-auto rounded-full bg-white text-primary text-sm px-2">Copiar</span>
            </button>
            <button @click="rotas()" type="button" class="anPulse mt-2 inline-flex w-full items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primarydark">
              <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              Rotas
            </button>
          </div>
          <div class="col-span-2 lg:col-span-3 border-4 h-full border-primary rounded-lg hover:shadow-lg">
            <iframe
              style="filter: invert(10%)"
              aria-hidden="false"
              height="100%"
              width="100%"
              class="rounded-lg shadow-lg"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3675.134281875456!2d-47.12019528556025!3d-22.908417443687288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c8c874d593d1bf%3A0x1e98110816410801!2zVlcgU29sdcOnw7Vlcw!5e0!3m2!1spt-BR!2sbr!4v1611796410388!5m2!1spt-BR!2sbr"
            ></iframe>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { firebase, listingsColl, productsColl } from '@/firebase'

export default {
  data() { return { hoverEmail: false, hoverLigar: false, hoverWhatsapp: false } },

  mounted() { if (this.$router.currentRoute.name === 'Contato') { this.scroll() } },

  methods: {
    async debug() {
      console.log("debug")
      let listing = []
      let listingIds = []
      await listingsColl.doc('Products').get().then(result => { listing = result.data(), listingIds = result.data().available.map(item => item.id) })
      console.log(listing), console.log(listingIds)
      
      await productsColl.get().then(docs => {
        docs.docs.forEach(async item => {
          if (!listingIds.includes(item.id)) { 
            console.log("nao contem", item.id)
            await listingsColl.doc('Products').update({ available: firebase.firestore.FieldValue.arrayUnion({
              categorie: item.data().categorie || '',
              id: item.id,
              image: item.data().image || '',
              price: item.data().priceFinal || 0,
              title: item.data().title || '',
              titleDestaque: item.data().titleDestaque || '',
              weight: item.data().weight || 0,
            }) })
          } 
        })
      }).catch(err => console.log(err))
    },

    email() { window.open('mailto:vwsolucoes@gmail.com', '_self') },

    ligar() { window.open('tel:+551932294700', '_self') },

    whatsapp() { window.open('https://api.whatsapp.com/send?phone=5519999987126', '_blank') },

    rotas() { window.open('https://goo.gl/maps/MF6fVQjZd2F1k8vQ9', '_blank') },

    async copy(content) { await navigator.clipboard.writeText(content).then(() => { this.$toast.success('Copiado para área de transferência') }) },

    scroll() { window.scroll(0, document.body.scrollHeight-800) }
  }
}
</script>

<style>
.anPulse:hover {
  animation: pulse 1s infinite;
  animation-timing-function: linear;
}

@keyframes animation {
	0% {transform: matrix(1,0,0,1,0,0); opacity: 1; animation-timing-function: linear}
	100% {transform: matrix(1,0,0,1,0,-15); opacity: 1; }
}

@keyframes pulse {
  0% {transform: scale(1);}
  50% {transform: scale(1.01);}
  100% {transform: scale(1);}
}
</style>